import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Share from "@material-ui/icons/Share";
import Search from "@material-ui/icons/Search";
import FileCopy from "@material-ui/icons/FileCopy";
import NavigateNext from "@material-ui/icons/ArrowForwardIos";
import NavigateBefore from "@material-ui/icons/ArrowBackIos";
import { iconColor } from "../constants/BaseConstants";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import Fab from "@material-ui/core/Fab";
import AppBar from "@material-ui/core/AppBar";

const useStyles = makeStyles(theme => ({
  bar: {
    top: "auto",
    bottom: 0
  },
  barContents: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  iconButton: {
    color: iconColor,
    marginLeft: "0px",
    marginRight: "0px",
    // eslint-disable-next-line
    ["@media (min-width:360px)"]: {
      marginLeft: "4px",
      marginRight: "4px"
    },
    // eslint-disable-next-line
    ["@media (min-width:400px)"]: {
      marginLeft: "8px",
      marginRight: "8px"
    }
  },
  leftGroup: {},
  rightGroup: {},
  centerGroup: {
    minWidth: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // eslint-disable-next-line
    ["@media (min-width:440px)"]: {
      minWidth: "160px"
    }
  },
  fabButton: {
    height: "47px",
    width: "47px",
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark
    }
  }
}));

interface BottomAppBarProps {
  isAudioPlaying: boolean;
  playPauseClicked: (event: React.MouseEvent<HTMLElement>) => void;
  nextClicked: (event: React.MouseEvent<HTMLElement>) => void;
  prevClicked: (event: React.MouseEvent<HTMLElement>) => void;
  searchClicked: (event: React.MouseEvent<HTMLElement>) => void;
  shareClicked: (event: React.MouseEvent<HTMLElement>) => void;
  copyClicked: (event: React.MouseEvent<HTMLElement>) => void;
}

function BottomAppBar(props: BottomAppBarProps) {
  const [isSharingAvailable, setIsSharingAvailable] = React.useState(true);
  const classes = useStyles(props);

  React.useEffect(() => {
    if(navigator.share) {
      setIsSharingAvailable(true);
    } else {
      setIsSharingAvailable(false);
    }
  }, []);

  return (
    <AppBar className={classes.bar}>
      <div className={classes.barContents}>
        <div className={classes.leftGroup}>
          {isSharingAvailable && (
            <IconButton onClick={props.shareClicked} className={classes.iconButton} title="Share Verses" aria-label="Share Verses">
              <Share />
            </IconButton>
          )}
          {!isSharingAvailable && (
            <IconButton
              onClick={props.copyClicked}
              className={classes.iconButton}
              title="Copy Verses to Clipboard"
              aria-label="Copy Verses to Clipboard"
            >
              <FileCopy />
            </IconButton>
          )}

          <IconButton onClick={props.searchClicked} className={classes.iconButton} title="Search" aria-label="Search">
            <Search />
          </IconButton>
        </div>

        <div className={classes.centerGroup}>
          <Fab onClick={props.playPauseClicked} className={classes.fabButton} title="Play/Stop" aria-label="Play/Stop">
            {props.isAudioPlaying === false && <PlayArrow />}
            {props.isAudioPlaying === true && <Pause />}
          </Fab>
        </div>

        <div className={classes.rightGroup}>
          <IconButton className={classes.iconButton} onClick={props.prevClicked} title="Previous Surah" aria-label="Previous Surah">
            <NavigateBefore />
          </IconButton>

          <IconButton className={classes.iconButton} onClick={props.nextClicked} title="Next Surah" aria-label="Next Surah">
            <NavigateNext />
          </IconButton>
        </div>
      </div>
    </AppBar>
  );
}

export default React.memo(BottomAppBar);
