import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { scroller, Events } from "react-scroll";
import { Chapter } from "../models/Chapter";
import { quranFonts } from "../constants/QuranFonts";
import { VerseLocationForText } from "../models/VerseLocationForText";
import "../css/global-styles.css";
import { ToHindi } from "../common/ToHindi";
import Typography from "@material-ui/core/Typography";
import { textThemes } from "../constants/TextThemes";
import { TEXTBOOKS } from "../constants/Textbooks";
import { arraysEqual } from "../common/ArraysEqual";
import { notesArraysEqual } from "../common/NotesArraysEqual";
import { TextTheme } from "../models/TextTheme";
import NoteComponent from "../components/NoteComponent";
import { Note } from "../models/Note";

// @ts-ignore
import renderHTML from "react-render-html";

const topAppBarHeight = 55;
const bottomAppBarHeight = 55;
const verticalOffset = 6;
const defaultFontSizeB = 1.1; // in rem
let textTheme: TextTheme;

interface QuranTextProps {
  chapter: Chapter;
  verseLocation: VerseLocationForText;
  quranFont: string;
  fontScaling: number;
  chapterRenderingDone: () => void;
  verseScrollingDone: (verseNumber: number) => void;
  verseClicked: (verseNumber: number) => void;
  isDarkTheme: boolean;
  isParallelText: boolean;
  notes: Note[];
  saveNote: (note: Note) => void;
}

const arePropsEqual = (prevProps: QuranTextProps, nextProps: QuranTextProps) => {
  const areEqual =
    prevProps.chapter.chapterNumber === nextProps.chapter.chapterNumber &&
    arraysEqual(prevProps.chapter.textBooks, nextProps.chapter.textBooks) &&
    prevProps.verseLocation.verseNumber === nextProps.verseLocation.verseNumber &&
    prevProps.quranFont === nextProps.quranFont &&
    prevProps.fontScaling === nextProps.fontScaling &&
    prevProps.isDarkTheme === nextProps.isDarkTheme &&
    prevProps.isParallelText === nextProps.isParallelText &&
    notesArraysEqual(prevProps.notes, nextProps.notes)
  return areEqual;
};

function QuranText(props: QuranTextProps) {
  textTheme = props.isDarkTheme ? textThemes[1] : textThemes[0];

  const useStyles = makeStyles(() => ({
    titleQuranBlock: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      paddingTop: "10px",
      paddingBottom: "25px",
      color: textTheme.titles,
      fontWeight: "normal",
      letterSpacing: "0"
    },
    titleQuran1: {
      fontSize: "25px",
      paddingTop: "3px",
      paddingBottom: "6px"
    },
    titleQuran2: {
      fontSize: "18px",
      textAlign: "center",
      padding: "0px 10px 0px 10px"
    },
    titleChapter: {
      fontSize: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "15px",
      paddingBottom: "20px",
      fontFamily: "'ms serif',new york,serif",
      fontVariant: "normal",
      color: textTheme.titles
    },
    verseBlock: {
      color: textTheme.text,
      display: "flex",
      padding: "px 0px 5px 0px" // controls space between text and horizontal lines
    },
    verseBlockRows: {
      flexDirection: "column"
    },
    verseBlockColumns: {
      flexDirection: "row"
    },
    verseA: {
      margin: "2px 6px 2px 6px",
      flex: "1 1 0px"
    },
    verseB: {
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because MUI Typography added another div
      lineHeight: "1.4",
      margin: "2px 8px 5px 8px",
      flex: "1 1 0px"
    },
    verseBlockSelected: {
      backgroundColor: textTheme.verseSelectedBackground
    },
    verseBlockNotSelected: {
      backgroundColor: textTheme.background
    },
    borderTopDeep: {
      borderTop: "1px solid " + textTheme.verseSelectedBorder
    },
    borderBottomDeep: {
      borderBottom: "1px solid " + textTheme.verseSelectedBorder
    },
    borderTopLight: {
      borderTop: "1px solid " + textTheme.borderBottomLight
    },
    borderBottomLight: {
      borderBottom: "1px solid " + textTheme.borderBottomLight
    },
    borderTopBlank: {
      borderTop: "1px solid " + textTheme.background
    },
    borderBottomBlank: {
      borderBottom: "1px solid " + textTheme.background
    },
    verseNumberB: {
      fontSize: "90%",
      color: textTheme.verseNumber,
      backgroundColor: textTheme.verseNumberBackground,
      padding: "1px 4px 1px 4px",
      borderRadius: "45%"
    },
    verseNumberA: {
      fontSize: "85%",
      color: textTheme.verseNumber
    },
    spaceBetweenVerses: {
      padding: "2px 0px 2px 0px"
    },
    translationName: {
      fontSize: "85%",
      paddingTop: "0px",
      color: textTheme.translationName
    },
    note: {
      margin: "8px 12px 10px 32px",

    }
  }));

  const classes = useStyles(props);
  function handleVerseClicked(event: React.MouseEvent<HTMLElement>) {
    props.verseClicked(Number(event.currentTarget.id.replace("verse", "")));
  }

  React.useEffect(() => {
    Events.scrollEvent.register("end", function (to) {
      // perform check, because scroll also happens in navigation drawer
      if (to.startsWith("verse")) {
        // setTimeout is needed, otherwise weird behavior
        props.verseScrollingDone(+to.replace("verse", ""));
      }
    });
  });

  function isElementInViewport(element: HTMLElement, topOffset: number, bottomOffset: number) {
    try {
      const rect = element.getBoundingClientRect();
      return (
        rect.top - topOffset >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - bottomOffset /*or $(window).height() */
      );
    } catch (error) {
      return true;
    }
  }

  function isElementScrolledUp(element: HTMLElement, yOffset: number) {
    try {
      const rect = element.getBoundingClientRect();
      return rect.top - yOffset >= 0;
    } catch (error) {
      return true;
    }
  }

  React.useEffect(() => {
    const itemVerse = document.getElementById("verse" + props.verseLocation.verseNumber);
    const isVerseInViewPort = isElementInViewport(itemVerse, +topAppBarHeight - verticalOffset, bottomAppBarHeight - verticalOffset);
    const itemTitles = document.getElementById("chapter-titles");
    const isTitlesScrolledUp = isElementScrolledUp(itemTitles, +topAppBarHeight - verticalOffset);

    if (props.verseLocation.isScrollToVerse) {
      if (!document.hidden) {
        // scrolling done only if document is visible
        if (props.verseLocation.verseNumber === 0 || props.verseLocation.verseNumber === 1) {
          if (!isTitlesScrolledUp) {
            // if already on top, don't scroll
            const option = {
              smooth: props.verseLocation.isAnimateScroll ? true : false,
              offset: -topAppBarHeight - 300 // force all the way up
            };
            scroller.scrollTo("verse" + props.verseLocation.verseNumber, option);
          } else {
            props.verseScrollingDone(props.verseLocation.verseNumber);
          }
        } else if (!isVerseInViewPort) {
          // if already in viewport, don't scroll
          const option = {
            smooth: "verseLocation.isAnimateScroll ? true : false",
            offset: -topAppBarHeight - verticalOffset
          };
          scroller.scrollTo("verse" + props.verseLocation.verseNumber, option);
        } else {
          props.verseScrollingDone(props.verseLocation.verseNumber);
        }
      } else {
        props.verseScrollingDone(props.verseLocation.verseNumber);
      }
    } else {
      // do not scroll, since document is hidden
      props.verseScrollingDone(props.verseLocation.verseNumber);
    }
    // eslint-disable-next-line
  }, [props.verseLocation.verseNumber, props.verseLocation.isForceNewVerseNumber]);

  React.useEffect(() => {
    props.chapterRenderingDone();
    // eslint-disable-next-line
  }, [props.chapter]);

  const arabicBlockStyle = {
    fontSize: ((props.fontScaling * quranFonts.filter((font) => font.name === props.quranFont)[0].scaling) / 100).toString() + "rem"
  };

  const englishBlockStyle = {
    fontSize: ((props.fontScaling * defaultFontSizeB) / 100).toString() + "rem"
  };

  // If parallel text, display only the first two textbooks
  const textBooks = Object.assign([], props.chapter.textBooks); // Clone array of textbooks
  if (props.isParallelText && textBooks.length > 2) {
    textBooks.length = 2;
  }

 /* for(let i=0; i< props.chapter.verses.length; i++) 
    for(let j=0; i<props.chapter.verses[i].length; j++) {
      if(textBooks[j] === "ar-talal") {

      }
    } 
  } */

  return (
    <div>
      {props.chapter.chapterNumber === 1 && (
        <div color="primary" className={classes.titleQuranBlock} id="chapter-titles">
          <Typography className={classes.titleQuran1} component="h1">Quran Online</Typography>
          <Typography className={classes.titleQuran2} component="h2">Read • write notes • listen • memorize • search • share • multilingual</Typography>
        </div>
      )}
      {props.chapter.chapterNumber !== 1 && (
        <Typography color="primary" className={classes.titleChapter} variant="h1" id="chapter-titles">
          {props.chapter.nameEnglish}
        </Typography>
      )}

      {/* loop through verses */}
      {props.chapter.verseNumbers.map((verseNumber: number, verseIndex: number) => (
        <div
          id={"verse" + verseNumber.toString()}
          key={"verse" + verseNumber.toString()}
          onClick={handleVerseClicked}
          className={
            classes.verseBlock +
            " " +
            (props.isParallelText ? classes.verseBlockColumns : classes.verseBlockRows) +
            " " +
            (props.verseLocation.verseNumber === verseNumber ? classes.verseBlockSelected : classes.verseBlockNotSelected) +
            " " +
            (props.verseLocation.verseNumber === verseNumber ? classes.borderTopDeep + " " + classes.borderBottomDeep : null) +
            " " +
            (props.verseLocation.verseNumber !== verseNumber && textBooks.length > 1 ? classes.borderTopBlank + " " + classes.borderBottomLight : null) +
            " " +
            (props.verseLocation.verseNumber !== verseNumber && textBooks.length === 1 ? classes.borderTopBlank + " " + classes.borderBottomBlank : null) +
            " " +
            (props.verseLocation.verseNumber === verseNumber + 1 ? classes.borderBottomBlank : null) +
            " " +
            (props.isParallelText && props.verseLocation.verseNumber !== verseNumber ? classes.borderBottomBlank : null)
          }
        >
          {/* Loop through textbooks */}
          {textBooks.map((textBook, textBookIndex) => (
            <div key={"text-book" + textBookIndex} className={classes.verseA}>
              {/* If Quran Arabic Text */}
              { (textBook === "ar-allah" || textBook === "ar-talal") && (
                <div dir="rtl" lang="ar" style={arabicBlockStyle}>
                  {verseNumber !== 0 && (
                    <span
                      className={classes.verseNumberA}
                      style={{
                        fontFamily: quranFonts.filter((font) => font.name === props.quranFont)[0].cssNameForNumber
                      }}
                    >
                      ﴿{ToHindi(verseNumber, "ar")}﴾&nbsp;
                    </span>
                  )}
                  <span
                    style={{
                      fontFamily: quranFonts.filter((font) => font.name === props.quranFont)[0].cssName,
                      lineHeight: quranFonts.filter((font) => font.name === props.quranFont)[0].lineHeight,
                      wordSpacing: quranFonts.filter((font) => font.name === props.quranFont)[0].wordSpacing
                    }}
                  >
                    {props.chapter.verses[textBookIndex][verseIndex]}
                  </span>
                </div>
              )}
              {/* If not Quran Arabic Text */}
              {(textBook !== "ar-allah" && textBook !== "ar-talal") &&(
                <div
                  style={englishBlockStyle}
                  dir={TEXTBOOKS.filter((book) => book.translationCode === textBook)[0].direction}
                  lang={TEXTBOOKS.filter((book) => book.translationCode === textBook)[0].languageCode}
                  className={classes.verseB}
                >
                  <div>
                    {verseNumber !== 0 && (
                      <span className={classes.verseNumberB}>
                        {ToHindi(verseNumber, TEXTBOOKS.filter((book) => book.translationCode === textBook)[0].languageCode)}
                      </span>
                    )}
                    {verseNumber !== 0 && <span>&thinsp;&thinsp;</span>}
                    {/* Display HTML because some text contains html tags (like transliteration) */}
                    {renderHTML(props.chapter.verses[textBookIndex][verseIndex])}
                    {textBooks.length > 2 && (
                      <span className={classes.translationName}> ({TEXTBOOKS.filter((book) => book.translationCode === textBook)[0].translationName})</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}

          {props.notes.filter((item) => item.verseNumber === verseNumber).length > 0 && (
            <div className={classes.note}>
              <NoteComponent 
                note={props.notes.filter((item) => item.verseNumber === verseNumber)[0]} 
                saveNote={(note: Note) => props.saveNote(note)}
                fontScaling={props.fontScaling}
                isDarkTheme={props.isDarkTheme}
              ></NoteComponent>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default React.memo(QuranText, arePropsEqual);
