import React from "react";
import axios from "axios";
import { baseTextUrl } from "../constants/BaseConstants";
import { ChapterInfo } from "../models/ChapterInfo";
import { Chapter } from "../models/Chapter";
import { gaEvent } from "../common/GaEvent";

function getFileNumberWithLeadingZeros(num: number) {
  return ("00" + num).slice(-3);
}

interface GetChapterProps {
  textBooks: string[];
  chapterNumber: number;
  newChapterLoaded: (chapter: Chapter) => void;
  chaptersInfo: ChapterInfo[];
}

function GetChapter(props: GetChapterProps): null {
  React.useEffect(() => {
    if (props.textBooks.length > 0) {
      gaEvent("Load TextBooks", "none", props.textBooks.join(", "), 0);
      const urls = props.textBooks.map(
        (textBook: string) => `${baseTextUrl}${textBook}/${getFileNumberWithLeadingZeros(props.chapterNumber)}.txt`
      );
      axios
        .all(urls.map((l) => axios.get(l)))
        .then(
          axios.spread(function (...res) {
            const chapter = new Chapter();
            chapter.chapterNumber = props.chapterNumber;
            chapter.nameArabic = props.chaptersInfo[props.chapterNumber - 1].nameArabic;
            chapter.nameEnglish = props.chaptersInfo[props.chapterNumber - 1].nameEnglish;
            chapter.nameTrans = props.chaptersInfo[props.chapterNumber - 1].nameTrans;

            const verses: string[][] = [];
            for (let iii = 0; iii < res.length; iii++) {
              const versesList = res[iii].data.split(/\r?\n/);
              versesList.pop(); // remove last item from array (because it is an empty line)
              verses.push(versesList);
            }
            chapter.verses = verses;
            const verseNumbers: number[] = [];
            if (props.chapterNumber === 1 || props.chapterNumber === 9) {
              for (let i = 1; i <= verses[0].length; i++) {
                verseNumbers.push(i);
              }
            } else {
              for (let ii = 0; ii < verses[0].length; ii++) {
                verseNumbers.push(ii);
              }
            }
            chapter.verseNumbers = verseNumbers;
            chapter.textBooks = props.textBooks;
            props.newChapterLoaded(chapter);
          })
        )
        .catch((error) => {
          console.log("axios load chapters error" + error);
        });
    }
  });

  return null;
}

export default GetChapter;
