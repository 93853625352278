export const JUZTABLE = [
  [1, 1],
  [2, 142],
  [2, 253],
  [3, 93],
  [4, 24],
  [4, 148],
  [5, 82],
  [6, 111],
  [7, 88],
  [8, 41],
  [9, 93],
  [11, 6],
  [12, 53],
  [15, 0],
  [17, 0],
  [18, 75],
  [21, 1],
  [23, 0],
  [25, 21],
  [27, 56],
  [29, 46],
  [33, 31],
  [36, 28],
  [39, 32],
  [41, 47],
  [46, 0],
  [51, 31],
  [58, 0],
  [67, 0],
  [78, 0]
];
