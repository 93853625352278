export class QuranFont {
  public name: string;
  public cssName: string;
  public scaling: number;
  public lineHeight: number;
  public cssNameForNumber: string; // To use another font for verse-number
  public wordSpacing: string;

  public constructor(name: string, cssName: string, scaling: number, lineHeight: number, cssNameForNumber: string, wordSpacing: string) {
    this.name = name;
    this.cssName = cssName;
    this.scaling = scaling;
    this.lineHeight = lineHeight;
    this.cssNameForNumber = cssNameForNumber;
    this.wordSpacing = wordSpacing;
  }
}
