export const azureSearchRootUrl = "https://quransearch.search.windows.net/indexes/multilingual/docs";
export const siteUrl = "https://www.notequran.com/";
export const siteFancyUrl = "NoteQuran.com";
export const baseTextUrl = "https://text.quranwow.com/";
export const iconColor = "#e8e8e8";
export const blueUrlColor = "#1967d2";
export const topAppBarHeight = "54px";
export const gaTrackingCode = "G-YLQKW1WGEE";

export const siteTitle = "Quran: Arabic & Translations. Text, Recitations, Search";

