import React, {ReactElement} from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const serialize = require("form-serialize");

const useStyles = makeStyles(theme => ({
  containerTop: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginRight: theme.spacing(2)
  },
  textFieldMessage: {
    width: "100%"
  },
  submitButton: {
    marginTop: "12px",
    float: "right"
  },
  hidden: {
    display: "none"
  }
}));

function ContactForm(): ReactElement {
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");

  const classes = useStyles("");

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const data1 = serialize(document.getElementById("contact-form"));
    axios
      .post("/", data1)
      .then(() => {
        setSnackBarMessage("Message Sent");
        setIsSnackBarOpen(true);
      })
      .catch(() => {
        setSnackBarMessage("Error: Could not Sent Message!");
        setIsSnackBarOpen(true);
      });
    return false;
  }

  function handleCloseSnackBar() {
    (document.getElementById("contact-form-name") as HTMLInputElement).value = "";
    (document.getElementById("contact-form-email") as HTMLInputElement).value = "";
    (document.getElementById("contact-form-message") as HTMLInputElement).value = "";
    setIsSnackBarOpen(false);
  }

  return (
    <div>
      <form id="contact-form" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <div className={classes.containerTop}>
          <TextField name="subject" value="Contact NoteQuran.com" className={classes.hidden} />
          <TextField
            id="contact-form-name"
            required
            name="name"
            label="Name"
            defaultValue=""
            className={classes.textField}
            margin="dense"
            variant="outlined"
            autoComplete="name"
          />
          <TextField
            id="contact-form-email"
            required
            name="email"
            label="Email"
            defaultValue=""
            className={classes.textField}
            type="email"
            margin="dense"
            variant="outlined"
            autoComplete="email"
          />
        </div>
        <TextField
          id="contact-form-message"
          required
          name="message"
          label="Message"
          defaultValue=""
          className={classes.textFieldMessage}
          multiline
          rows="7"
          margin="dense"
          variant="outlined"
        />
        <div>
          <Button variant="outlined" color="primary" className={classes.submitButton} type="submit">
            &nbsp;Send&nbsp;
          </Button>
        </div>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={isSnackBarOpen}
        onClose={handleCloseSnackBar}
        autoHideDuration={2000}
        message={snackBarMessage}
      />
    </div>
  );
}

export default ContactForm;
