import { QuranFont } from "../models/QuranFont";

export const quranFonts: QuranFont[] = [
  new QuranFont("Uthmani", "Uthmani", 1.45, 1.8, "Traditional Arabic", "0rem"),
  new QuranFont("Hafs", "Hafs", 1.4, 2, "Traditional Arabic", "0rem"),
  new QuranFont("IndoPak", "IndoPak", 1.6, 1.6, "Traditional Arabic", "0.2rem"),
  new QuranFont("Kufi", "Kufi", 1.2, 2.0, "Kufi", "0rem"),
  new QuranFont("Nashk", "Nashk", 1.05, 2.1, "Nashk", "0rem"),
  new QuranFont("Scheherazade", "Scheherazade", 1.8, 1.0, "Scheherazade", "0rem"),
  new QuranFont("System", "", 1.3, 1.5, "", "0rem")
];
