export function ToHindi(n: number, lang: string): string {
  if (n === 0) return "";

  let ns: string[];
  const nnn = n.toString();

  if (lang === "ar" || lang === "ku")
    ns = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  else if (lang === "ur" || lang === "fa" || lang === "sd" || lang === "ug")
    ns = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  else ns = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  return nnn.toString().replace(/\d/g, function (m) {
    return ns[+m];
  });
}

