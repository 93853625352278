import React, {ReactElement} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ContactForm from "../components/ContactForm";
import { TransitionProps } from "@material-ui/core/transitions";

interface ContactDialogProps {
  isOpen: boolean;
  handleCloseDialog: (event: React.MouseEvent<HTMLElement>) => void;
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  container: {
    maxWidth: "700px",
    margin: "25px 25px 0px 25px"
  }
}));

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
 // @ts-ignore
 return <Slide direction="up" ref={ref} {...props} />
});

export default function ContactDialog(props: ContactDialogProps): ReactElement {
  const classes = useStyles(props);

  return (
    <Dialog fullScreen open={props.isOpen} onClose={props.handleCloseDialog} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleCloseDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Contact
          </Typography>
          <Button autoFocus color="inherit" onClick={props.handleCloseDialog}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <ContactForm />
      </div>
    </Dialog>
  );
}
