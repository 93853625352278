import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Note } from "../models/Note";
import { debounce } from "debounce";
import { textThemes } from "../constants/TextThemes";
import { TextTheme } from "../models/TextTheme";

let textTheme: TextTheme;
const defaultFontSize = 1.1; // in rem

interface NoteComponentProps {
  note: Note;
  saveNote: (note: Note) => void;
  fontScaling: number;
  isDarkTheme: boolean;
}

function NoteComponent(props: NoteComponentProps): ReactElement {
  textTheme = props.isDarkTheme ? textThemes[1] : textThemes[0];

  const useStyles = makeStyles(() => ({
    textField: {
      width: "100%"
    },
    textFieldText: {
      fontSize: ((props.fontScaling * defaultFontSize) / 100).toString() + "rem",
      color: textTheme.text
    },
    icon: {
      margin: 0,
      padding: "5px 10px 5px 10px"
    },
    floatingLabelFocusStyle: {
      color: textTheme.translationName,
      "&.Mui-focused": {
        color: textTheme.translationName
      }
    }
  }));

  const classes = useStyles(props);

  React.useEffect(() => {
    document.getElementById(`note-${props.note.verseNumber.toString()}`).setAttribute("dir", "auto"); // Because I could not do it with jsx
    document.getElementById(`note-${props.note.verseNumber.toString()}`).onkeyup = debounce(saveTheNote, 1250);
  });

  function saveTheNote() {
    const note = new Note(
      props.note.documentId,
      props.note.verseNumber,
      (document.getElementById(`note-${props.note.verseNumber.toString()}`) as HTMLInputElement).value
    );
    props.saveNote(note);
  }

  const label = props.note.verseNumber === 0? `Notes` : `${props.note.verseNumber.toString()}. Notes`;

  return (
    <TextField
      className={classes.textField}
      id={`note-${props.note.verseNumber.toString()}`}
      label={label}
      multiline
      fullWidth
      variant="filled"
      defaultValue={props.note.text}
      InputProps={{
        disableUnderline: true,
        className: classes.textFieldText
      }}
      InputLabelProps={{
        className: classes.floatingLabelFocusStyle
      }}
    />
  );
}

export default NoteComponent;
