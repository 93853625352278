import { Note } from "../models/Note";

export function notesArraysEqual(notesA: Note[], notesB: Note[]): boolean {
  if (notesA === notesB) return true;
  if (notesA == null || notesB == null) return false;
  if (notesA.length !== notesB.length) return false;

  for (let i = 0; i < notesA.length; ++i) {
    if (notesA[i].text !== notesB[i].text || notesA[i].verseNumber !== notesB[i].verseNumber) return false;
  }
  return true;
}
