import { createMuiTheme } from "@material-ui/core/styles";

export const MainTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#115293",
      light: "#3778B9"
    },
    secondary: {
      main: "#3778B9",
      dark: "#dc004e"
    }
  }
});
