import React, { ReactElement } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

// @ts-ignore
import renderHTML from "react-render-html";


interface DonateDialogProps {
  isOpen: boolean;
  handleCloseDialog: (event: React.MouseEvent<HTMLElement>) => void;
  handleDonate: (event: React.MouseEvent<HTMLElement>) => void;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#fff",
    color: "#333"
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"

  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#606060"

  },
  card: {
    margin: "20px 0px 25px 0px",
    width: "300px",
    textAlign: "center",
    marginLeft: "0px",
    // to overcome what seems to be a donorbox positioning bug
    // eslint-disable-next-line
    ["@media (min-width:490px)"]: { 
      marginLeft: "-70px",
    },
  }
}));

export default function DonateDialog(props: DonateDialogProps): ReactElement {
  const classes = useStyles(props);

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.handleCloseDialog}
      aria-labelledby="donate-dialog"
      aria-describedby="donate dialog"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" onClick={props.handleCloseDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Donate
          </Typography>
          <Button autoFocus color="inherit" onClick={props.handleCloseDialog}>
            Close
          </Button>
        </Toolbar>
      </AppBar>


      <div className={classes.dialogContent}>
      
        <div className={classes.card}>One Cent brings One person to NoteQuran.com. One Dollar brings 100 persons. Keep the momentum going. Donate whatever you can.</div>

        {renderHTML(
          '<script src="https://donorbox.org/widget.js" paypalExpress="true"></script><iframe allowpaymentrequest="" frameborder="0" height="900px" name="donorbox" scrolling="no" seamless="seamless" src="https://donorbox.org/embed/note-quran" style="max-width: 500px; min-width: 250px; max-height:none!important" width="100%"></iframe>'
        )}



      </div>


    </Dialog>
  );
}
