import loadable from "@loadable/component";
import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { ChapterVerseLocation } from "../models/ChapterVerseLocation";
import GetChapter from "../components/GetChapter";
import ProgressBar from "../components/ProgressBar";
import TopAppBar from "../components/TopAppBar";
import DonateDialog from "../components/DonateDialog";
import QuranText from "../components/QuranText";
import { Chapter } from "../models/Chapter";
import { VerseLocationForText } from "../models/VerseLocationForText";
import * as localStorage from "local-storage";
import Snackbar from "@material-ui/core/Snackbar";
import BottomAppBar from "../components/BottomAppBar";
import { ChapterInfo } from "../models/ChapterInfo";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MainTheme } from "../constants/MainTheme";
import { textThemes } from "../constants/TextThemes";
import { JUZTABLE } from "../constants/JuzTable";
import { arraysEqual } from "../common/ArraysEqual";
import { computeUrlPath } from "../common/ComputeUrlPath";
import { gaEvent } from "../common/GaEvent";
import { getTextBooksFromUrl, getRecitationsFromUrl, getChapterNumberFromUrl, getVerseNumberFromUrl } from "../common/GetFromUrl";
import { siteFancyUrl, siteTitle } from "../constants/BaseConstants";
import quranInfo from "../constants/quran-info.json";
import chapter001 from "../constants/quran-001.json";
import ReactGA from "react-ga4";
import { gaTrackingCode } from "../constants/BaseConstants";

import { Note } from "../models/Note";
import * as firebase from "firebase/app";
import { db, firebaseApp } from "../services/Firebase";
import "firebase/auth";

import ContactDialog from "../components/ContactDialog";

const ChaptersMenu = loadable(() => import("../components/ChaptersMenu"));
const SettingsDrawer = loadable(() => import("../components/SettingsDrawer"));
const AudioComponent = loadable(() => import("../components/AudioComponent"));
const MenuPages = loadable(() => import("../components/MenuPages"));
const NotesMenu = loadable(() => import("../components/NotesMenu"));
const SearchDialog = loadable(() => import("../components/SearchDialog"));
const SignInDialog = loadable(() => import("../components/SignInDialog"));
const ConfirmationDialog = loadable(() => import("../components/ConfirmationDialog"));
const VersesDialog = loadable(() => import("../components/VersesDialog"));
const ShareVersesDialog = loadable(() => import("../components/ShareVersesDialog"));
const AboutDialog = loadable(() => import("../components/AboutDialog"));
const ETeacherHelpDialog = loadable(() => import("../components/ETeacherHelpDialog"));
const JuzMenu = loadable(() => import("../components/JuzMenu"));
const InfoDialog = loadable(() => import("../components/InfoDialog"));
const FontScalingMenu = loadable(() => import("../components/FontScalingMenu"));

// eslint-disable-next-line @typescript-eslint/no-empty-function
let dbUnsubscribe = () => {};

let forceNewVerseNumberForText = 0; // To force scrolling to verse, even when verse number does not change
let isForceGetChapter = false;

let urlHash = "";
let bookmark: ChapterVerseLocation;
const maxWidthNarrow = "725px";
const maxWidthWide = "925px";
let timeStamp = 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function App(): ReactElement {
  const chaptersInfo: ChapterInfo[] = quranInfo;

  const verseRepeats = [];
  for (let i = 1; i <= 20; i++) {
    verseRepeats.push(i);
  }

  const defaultTextBooks = ["ar-allah", "en-itaniv2a"];
  const withStar = ["ar-allah", "en-itaniv2a", "alafasy-64", "iv2-pol-bri-a"];
  const defaultSearchTextBooks = ["ar-allah"];
  const defaultRecitations = ["alafasy-64"];
  const defaultIsAutoScroll = true;
  const defaultIsDarkTheme = false;
  const defaultIsParallelText = false;
  const defaultIsAutoNextChapter = true;
  const defaultFontScaling = 100;
  const defaultVerseRepeat = 1;
  const defaultChapter: Chapter = chapter001;
  const defaultQuranFont = "Hafs";
  const defaultIsShowNotes = true;

  const [textBooks, setTextBooks] = React.useState(defaultTextBooks);
  const [searchTextBooks, setSearchTextBooks] = React.useState(defaultSearchTextBooks);
  const [recitations, setRecitations] = React.useState(defaultRecitations);
  const [recitationPointer, setRecitationPointer] = React.useState(0);
  const [fontScaling, setFontScaling] = React.useState(defaultFontScaling);
  const [quranFont, setQuranFont] = React.useState(defaultQuranFont);
  const [verseRepeat, setVerseRepeat] = React.useState(defaultVerseRepeat);
  const [verseRepeatCounter, setVerseRepeatCounter] = React.useState(defaultVerseRepeat);
  const [isAutoScroll, setIsAutoScroll] = React.useState(defaultIsAutoScroll);
  const [isDarkTheme, setIsDarkTheme] = React.useState(defaultIsDarkTheme);
  const [isParallelText, setIsParallelText] = React.useState(defaultIsParallelText);
  const [isAutoNextChapter, setIsAutoNextChapter] = React.useState(defaultIsAutoNextChapter);

  const [isAudioPlaying, setIsAudioPlaying] = React.useState(false);
  const [isAudioPlaying2, setIsAudioPlaying2] = React.useState(true);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = React.useState(false);
  const [chapter, setChapter] = React.useState(defaultChapter);
  const [verseNumberForAudio, setVerseNumberForAudio] = React.useState(chapter.verseNumbers[0]);
  const [isProgressBarVisible, setIsProgressBarVisible] = React.useState(false);
  const [isProgressBar2Visible, setIsProgressBar2Visible] = React.useState(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = React.useState(false);

  const defaultVerseLocationForText = new VerseLocationForText(defaultChapter.verseNumbers[0], false, false, 0);
  const defaultChapterVerseLocation = new ChapterVerseLocation(defaultChapter.chapterNumber, defaultVerseLocationForText);

  const [verseLocationForText, setVerseLocationForText] = React.useState(defaultVerseLocationForText);
  const [chapterVerseLocation, setChapterVerseLocation] = React.useState(defaultChapterVerseLocation);

  const [isShareVersesDialogOpen, setIsShareVersesDialogOpen] = React.useState(false);
  const [isVerseDialogOpen, setIsVerseDialogOpen] = React.useState(false);

  const [anchorChapterMenu, setAnchorChapterMenu] = React.useState(null);
  const [anchorJuzMenu, setAnchorJuzMenu] = React.useState(null);
  const [anchorPagesMenu, setAnchorPagesMenu] = React.useState(null);
  const [anchorFontScalingMenu, setAnchorFontScalingMenu] = React.useState(null);
  const [anchorNotesMenu, setAnchorNotesMenu] = React.useState(null);

  const [isAboutDialogOpen, setIsAboutDialogOpen] = React.useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = React.useState(false);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = React.useState(false);
  const [isETeacherHelpDialogOpen, setIsETeacherHelpDialogOpen] = React.useState(false);
  const [isShare, setIsShare] = React.useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");


  const [notes, setNotes] = React.useState([]);
  const [isSignInDialogOpen, setIsSignInDialogOpen] = React.useState(false);
  const [isSignInConfirmationDialogOpen, setIsSignInConfirmationDialogOpen] = React.useState(false);
  const [isShowNotes, setIsShowNotes] = React.useState(true);
  const [isShowNotesButton, setIsShowNotesButton] = React.useState(false); // show this button only when auth makes a decision

  const [isDonateDialogOpen, setIsDonateDialogOpen] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    contentNarrow: {
      maxWidth: maxWidthNarrow,
      margin: "auto"
    },
    contentWide: {
      maxWidth: maxWidthWide,
      margin: "auto"
    },
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: isDarkTheme ? textThemes[1].background : textThemes[0].background
    },
    main: {
      margin: 0,
      padding: 0
    },
    toolbar: theme.mixins.toolbar,
    fontUthmani: {
      fontFamily: "Uthmani"
    },
    fontIndopak: {
      fontFamily: "Indopak"
    },
    fontScheherazade: {
      fontFamily: "Scheherazade"
    },
    fontSystem: {
      fontFamily: "System"
    },
    bookmarkSnackbar: {
      bottom: "60px"
    }
  }));

  const classes = useStyles();

  if (typeof document !== "undefined") {
    document.body.style.backgroundColor = isDarkTheme ? textThemes[1].background : textThemes[0].background;
  }

  if (typeof window !== "undefined") {
    urlHash = window.location.hash;
  }

  React.useEffect(() => {
    firebaseApp.auth().onAuthStateChanged((user: any) => {
      setIsShowNotesButton(true); 
      if (!user || !isShowNotes) {
        setNotes([]);
        dbUnsubscribe();
        setIsProgressBar2Visible(false);
      } else {
        // remove parameter added by firebaseUI - when signing with email address
        window.history.replaceState(null, null, window.location.href.replace("?mode=select", ""));

        // register listener to database - first unregister previous registration
        setIsProgressBar2Visible(true);
        setNotes([]);
        dbUnsubscribe();
        dbUnsubscribe = db
          .collection("notes")
          .where("userId", "==", user.uid)
          .where("chapterNumber", "==", chapter.chapterNumber)
          .onSnapshot(function (querySnapshot) {
            const notes: Note[] = [];
            querySnapshot.forEach(function (doc) {
              notes.push(new Note(doc.id, doc.data().verseNumber, doc.data().text));
            });
            setNotes(notes);
            setIsProgressBar2Visible(false);
          });
      }
    });
  }, [chapter.chapterNumber, isShowNotes]);

  React.useEffect(() => {
    ReactGA.initialize(gaTrackingCode);
  }, [chapter.chapterNumber]);

  React.useEffect(() => {
    bookmark = localStorage.get<ChapterVerseLocation>("BOOKMARK") != null ? localStorage.get<ChapterVerseLocation>("BOOKMARK") : null;
    // set state from local storage
    setTextBooks(localStorage.get<string[]>("TEXT-BOOKS") != null ? localStorage.get<string[]>("TEXT-BOOKS") : defaultTextBooks);
    setSearchTextBooks(
      localStorage.get<string[]>("SEARCH-TEXT-BOOKS") != null ? localStorage.get<string[]>("SEARCH-TEXT-BOOKS") : defaultSearchTextBooks
    );
    setRecitations(localStorage.get<string[]>("RECITATIONS") != null ? localStorage.get<string[]>("RECITATIONS") : defaultRecitations);
    setFontScaling(localStorage.get<number>("FONT-SCALING") != null ? localStorage.get<number>("FONT-SCALING") : defaultFontScaling);
    setQuranFont(localStorage.get<string>("QURAN-FONT") != null ? localStorage.get<string>("QURAN-FONT") : defaultQuranFont);
    setVerseRepeat(localStorage.get<number>("VERSE-REPEAT") != null ? localStorage.get<number>("VERSE-REPEAT") : defaultVerseRepeat);
    setVerseRepeatCounter(localStorage.get<number>("VERSE-REPEAT") != null ? localStorage.get<number>("VERSE-REPEAT") : defaultVerseRepeat);
    setIsAutoScroll(
      localStorage.get<boolean>("IS-AUTO-SCROLL") != null ? localStorage.get<boolean>("IS-AUTO-SCROLL") : defaultIsAutoScroll
    );
    setIsDarkTheme(localStorage.get<boolean>("IS-DARK-THEME") != null ? localStorage.get<boolean>("IS-DARK-THEME") : defaultIsDarkTheme);
    setIsParallelText(
      localStorage.get<boolean>("IS-PARALLEL-TEXT") != null ? localStorage.get<boolean>("IS-PARALLEL-TEXT") : defaultIsParallelText
    );
    setIsAutoNextChapter(
      localStorage.get<boolean>("IS-AUTO-NEXT-CHAPTER") != null
        ? localStorage.get<boolean>("IS-AUTO-NEXT-CHAPTER")
        : defaultIsAutoNextChapter
    );
    setIsShowNotes(localStorage.get<boolean>("IS-SHOW-NOTES") != null ? localStorage.get<boolean>("IS-SHOW-NOTES") : defaultIsShowNotes);

    // If logging in with email, need to open sign-in dialog again - because the way firebaseUI operates with email login
    if (new URL(window.location.href).searchParams.get("mode") === "select") {
      setIsSignInDialogOpen(true);
    }

    // verseNumber
    const verseNumber = getVerseNumberFromUrl(urlHash);

    // chapterNumber
    const chapterNumber = getChapterNumberFromUrl(urlHash);

    // textBooks
    let isLoadNewText = false;
    let newTextBooks = getTextBooksFromUrl(urlHash);
    if (newTextBooks.length !== 0) {
      setTextBooks(newTextBooks);
      isLoadNewText = true;
    } else if (
      localStorage.get<string[]>("TEXT-BOOKS") != null &&
      !arraysEqual(localStorage.get<string[]>("TEXT-BOOKS"), defaultTextBooks)
    ) {
      isLoadNewText = true;
      newTextBooks = localStorage.get<string[]>("TEXT-BOOKS");
    }

    // Recitations
    const recitationsFromUrl = getRecitationsFromUrl(urlHash);
    if (recitationsFromUrl.length !== 0) {
      setRecitations(recitationsFromUrl);
    }

    if (isLoadNewText) {
      if (verseNumber === -1 && chapterNumber === -1) {
        isForceGetChapter = true;
        setIsProgressBarVisible(true); // because handleNewChapter displays progress-bar only if new Chapter
        handleNewChapter(1); // To top of Chapter 1
      } else if (chapterNumber === -1 && verseNumber !== -1) {
        isForceGetChapter = true;
        const chapterVerseLocation = new ChapterVerseLocation(1, new VerseLocationForText(verseNumber, true, true, 0)); // keep same location
        setChapterVerseLocation(chapterVerseLocation);
      } else if (chapterNumber !== -1 && verseNumber !== -1) {
        const gotoLocationLocal = new ChapterVerseLocation(chapterNumber, new VerseLocationForText(verseNumber, true, true, 0)); // to location
        gotoLocation(gotoLocationLocal);
      } else if (chapterNumber !== -1 && verseNumber === -1) {
        handleNewChapter(chapterNumber); // top of new chapter
      }
    } else {
      if (chapterNumber !== -1 && verseNumber !== -1) {
        const chapterVerseLocation = new ChapterVerseLocation(chapterNumber, new VerseLocationForText(verseNumber, true, true, 0));
        setChapterVerseLocation(chapterVerseLocation);
      } else if (chapterNumber !== -1 && verseNumber === -1) {
        handleNewChapter(chapterNumber); // top of new chapter
      } else if (chapterNumber === -1 && verseNumber !== -1) {
        const verseLocation = new VerseLocationForText(verseNumber, true, true, 1);
        setVerseLocationForText(verseLocation);
      }
    }
    // eslint-disable-next-line
  }, []);

  // below, tried to make browser back/forward buttons works, but things did not work
  /*   React.useEffect(() => {
    window.onhashchange = function() {
      urlHash = window.location.hash;
      let chapterNumber = getChapterNumberFromUrl(urlHash);
      if (chapterNumber == -1) {
        chapterNumber = 1;
      }
      handleNewChapter(chapterNumber);
    };
  }); */

  React.useEffect(() => {
    localStorage.set<string[]>("TEXT-BOOKS", textBooks);
    localStorage.set<string[]>("SEARCH-TEXT-BOOKS", searchTextBooks);
    localStorage.set<string[]>("RECITATIONS", recitations);
    localStorage.set<number>("FONT-SCALING", fontScaling);
    localStorage.set<string>("QURAN-FONT", quranFont);
    localStorage.set<number>("VERSE-REPEAT", verseRepeat);
    localStorage.set<boolean>("IS-DARK-THEME", isDarkTheme);
    localStorage.set<boolean>("IS-PARALLEL-TEXT", isParallelText);
    localStorage.set<boolean>("IS-AUTO-SCROLL", isAutoScroll);
    localStorage.set<boolean>("IS-AUTO-NEXT-CHAPTER", isAutoNextChapter);
    localStorage.set<boolean>("IS-SHOW-NOTES", isShowNotes);
  }, [
    textBooks,
    searchTextBooks,
    recitations,
    quranFont,
    fontScaling,
    verseRepeat,
    isParallelText,
    isDarkTheme,
    isAutoScroll,
    isAutoNextChapter,
    isShowNotes
  ]);

  return (
    <MuiThemeProvider theme={MainTheme}>
      <div className={classes.root}>
        <main className={classes.main}>
          <div className={classes.toolbar} />
          <article className={isParallelText ? classes.contentWide : classes.contentNarrow}>
            <TopAppBar
              chapter={chapter}
              onClickMenuButton={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorPagesMenu(event.currentTarget);
                gaEvent("Menu Button Clicked", "none", "none", 0);
              }}
              onClickVerticalSwapButton={() => setIsVerseDialogOpen(true)}
              onClickSettingsButton={() => {
                setIsSettingsDrawerOpen(true);
                gaEvent("Settings Button Clicked", "none", "none", 0);
              }}
              onClickTitleBlock={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorChapterMenu(event.currentTarget);
                gaEvent("Titles Block Clicked", "none", "none", 0);
              }}
              onClickNoteButton={(event: React.MouseEvent<HTMLElement>) => {
                if (firebaseApp.auth().currentUser) {
                  setAnchorNotesMenu(event.currentTarget);
                } else {
                  setIsSignInConfirmationDialogOpen(true);
                }
                gaEvent("Notes Button Clicked", "none", "none", 0);
              }}
              onClickTextSizeButton={(event: React.MouseEvent<HTMLElement>) => setAnchorFontScalingMenu(event.currentTarget)}
              isShowNotesButton={isShowNotesButton}
            />
            <BottomAppBar
              isAudioPlaying={isAudioPlaying}
              playPauseClicked={handlePlayPause}
              nextClicked={handleNextButtonClicked}
              prevClicked={handlePrevButtonClicked}
              searchClicked={() => setIsSearchDialogOpen(true)}
              shareClicked={handleShareClicked}
              copyClicked={handleCopyClicked}
            />
            <QuranText
              chapter={chapter}
              fontScaling={fontScaling}
              quranFont={quranFont}
              isDarkTheme={isDarkTheme}
              isParallelText={isParallelText}
              verseClicked={handleVerseClicked}
              chapterRenderingDone={() => handleChapterRenderingDone()}
              verseScrollingDone={(verseNumber: number) => handleVerseScrollingDone(verseNumber)}
              verseLocation={verseLocationForText}
              notes={notes}
              saveNote={(note: Note) => saveNote(note)}
            />
          </article>

          <SignInDialog isOpen={isSignInDialogOpen} handleClose={() => setIsSignInDialogOpen(false)} />

          {recitations.length > 0 && (
            <AudioComponent
              isAudioPlaying={isAudioPlaying}
              isAudioPlaying2={isAudioPlaying2}
              chapter={chapter}
              verseNumber={verseNumberForAudio}
              recitations={recitations}
              recitationPointer={recitationPointer}
              handleRecitationPointerChange={(pointer: number) => setRecitationPointer(pointer)}
              audioEnded={() => handleAudioEnded()}
            />
          )}

          {(chapterVerseLocation.chapterNumber !== chapter.chapterNumber || isForceGetChapter) && (
            <GetChapter
              chaptersInfo={chaptersInfo}
              textBooks={textBooks}
              chapterNumber={chapterVerseLocation.chapterNumber}
              newChapterLoaded={handleNewChapterLoaded}
            />
          )}
          <Box p={7} />
        </main>

        <ChaptersMenu
          anchor={anchorChapterMenu}
          chaptersInfo={chaptersInfo}
          chapterClicked={(chapterIndex: number) => handleNewChapter(chapterIndex + 1)}
          handleMenuClose={() => setAnchorChapterMenu(null)}
          currentChapterNumber={chapter.chapterNumber}
        />

        <SettingsDrawer
          selectedTextBooks={textBooks}
          defaultTextBooks={defaultTextBooks}
          handleTextBooksChange={handleTextBooksChange}
          selectedRecitations={recitations}
          defaultRecitations={defaultRecitations}
          withStar={withStar}
          handleRecitationsChange={handleRecitationsChange}
          isOpen={isSettingsDrawerOpen}
          handleCloseDrawer={() => setIsSettingsDrawerOpen(false)}
          handleOpenDrawer={() => setIsSettingsDrawerOpen(true)}
          selectedAutoScroll={isAutoScroll}
          handleAutoScrollChange={(autoScroll: boolean) => setIsAutoScroll(autoScroll)}
          isDarkTheme={isDarkTheme}
          handleDarkThemeChange={(darkTheme: boolean) => setIsDarkTheme(darkTheme)}
          isParallelText={isParallelText}
          handleParallelTextChange={(parallelText: boolean) => setIsParallelText(parallelText)}
          autoAdvanceChapter={isAutoNextChapter}
          handleAutoAdvanceChapter={(autoAdvanceChapter: boolean) => setIsAutoNextChapter(autoAdvanceChapter)}
          selectedQuranFont={quranFont}
          handleQuranFontChange={(quranFont: string) => setQuranFont(quranFont)}
          selectedFontScaling={fontScaling}
          handleFontScalingChange={(fontScaling: number) => setFontScaling(fontScaling)}
          handleVerseRepeatChange={(verseRepeat: number) => handleVerseRepeatInput(verseRepeat)}
          verseRepeat={verseRepeat}
          handleHelpButtonClicked={() => setIsHelpDialogOpen(true)}
          handleETeacherHelpButtonClicked={() => setIsETeacherHelpDialogOpen(true)}
        />

        <MenuPages
          anchor={anchorPagesMenu}
          isSignedIn={firebaseApp.auth().currentUser ? true : false}
          signOutClicked={() => {
            firebaseApp.auth().signOut();
            setAnchorPagesMenu(null);
          }}
          signInClicked={() => {
            setIsSignInDialogOpen(true);
            setAnchorPagesMenu(null);
          }}
          isShowBookmarkEntries={true}
          chapterNumber={chapter.chapterNumber}
          aboutClicked={() => {
            setIsAboutDialogOpen(true);
            setAnchorPagesMenu(null);
            gaEvent("About Clicked", "none", "none", 0);
          }}
          contactClicked={() => {
            setIsContactDialogOpen(true);
            setAnchorPagesMenu(null);
            gaEvent("Contact Clicked", "none", "none", 0);
          }}
          bookmarkClicked={handleBookmarkClicked}
          gotoBookmarkClicked={handleGotoBookmarkClicked}
          handleMenuClose={() => setAnchorPagesMenu(null)}
          handleGoToURL={(url: string) => gotoNewUrl(url)}
          juzMenuClicked={handleJuzMenuCLicked}
          donateClicked={() => {
            setAnchorPagesMenu(null); 
            setIsDonateDialogOpen(true);
            gaEvent("Donate Clicked", "none", "none", 0);
          }}          
        />

        <AboutDialog isOpen={isAboutDialogOpen} handleCloseDialog={() => setIsAboutDialogOpen(false)} />

        <DonateDialog isOpen={isDonateDialogOpen} handleCloseDialog={() => setIsDonateDialogOpen(false)}  handleDonate={() => {
          setIsDonateDialogOpen(false);
          }
        }/>  

        <InfoDialog
          paragraphs={[
            "• Select one or more Texts.",
            "• Select one or more Recitations.",
            "• Selecting multiple Texts can reduce the responsiveness of the App, especially for long suras on mobile devices."
          ]}
          title1={siteFancyUrl}
          title2={siteTitle}
          isOpen={isHelpDialogOpen}
          handleCloseDialog={() => setIsHelpDialogOpen(false)}
        />

        <ETeacherHelpDialog
          isOpen={isETeacherHelpDialogOpen}
          handleCloseDialog={() => setIsETeacherHelpDialogOpen(false)}
        />        

        <ContactDialog isOpen={isContactDialogOpen} handleCloseDialog={() => setIsContactDialogOpen(false)} />

        <SearchDialog
          fontScaling={fontScaling}
          quranFont={quranFont}
          isOpen={isSearchDialogOpen}
          handleClose={() => setIsSearchDialogOpen(false)}
          searchVerseClick={gotoLocation}
          chaptersInfo={chaptersInfo}
          selectedSearchTextBooks={searchTextBooks}
          defaultTextBooks={defaultSearchTextBooks}
          handleSearchTextBooksChange={handleSearchTextBooksChange}
          withStar={withStar}
        />

        <ConfirmationDialog
          title={"Write Notes • Save Notes"}
          message={"Sign In to write and save notes. Notes can be added to any verse."}
          okButtonLabel="Sign In"
          cancelButtonLabel="Cancel"
          isOpen={isSignInConfirmationDialogOpen}
          handleClose={() => setIsSignInConfirmationDialogOpen(false)}
          handleOk={() => {
            setIsSignInConfirmationDialogOpen(false);
            setIsSignInDialogOpen(true);
          }}
        />

        <ProgressBar isVisible={isProgressBarVisible} />
        <ProgressBar isVisible={isProgressBar2Visible} />

        <VersesDialog
          isOpen={isVerseDialogOpen}
          chapter={chapter}
          verseNumber={verseNumberForAudio}
          verseNumberClicked={(verseNumber: number) => handleVersesDialogItemClick(verseNumber)}
          handleCloseDialog={() => setIsVerseDialogOpen(false)}
        />
        <JuzMenu
          anchor={anchorJuzMenu}
          juzClicked={(juzIndex: number) => handleGotoJuz(juzIndex)}
          handleMenuClose={() => setAnchorJuzMenu(null)}
          chapterNumber={chapter.chapterNumber}
          verseNumber={verseLocationForText.verseNumber}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          className={classes.bookmarkSnackbar}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
        <ShareVersesDialog
          currentVerseNumber={verseLocationForText.verseNumber}
          chapter={chapter}
          isOpen={isShareVersesDialogOpen}
          handleCloseDialog={(message: string) => handleShareVersesDialog(message)}
          isShare={isShare}
          textBooks={textBooks}
          defaultTextBooks={defaultTextBooks}
          recitations={recitations}
          defaultRecitations={defaultRecitations}
        />
        <NotesMenu
          anchor={anchorNotesMenu}
          handleMenuClose={() => setAnchorNotesMenu(null)}
          verseNumber={verseNumberForAudio}
          notes={notes}
          addNote={addNoteHandler}
          deleteNote={deleteNoteHandler}
          isShowNotes={isShowNotes}
          handleIsShowNotesChange={(isShown: boolean) => {
            setIsShowNotes(isShown);
          }}
        />
        <FontScalingMenu
          anchor={anchorFontScalingMenu}
          fontScaling={fontScaling}
          handleMenuClose={() => setAnchorFontScalingMenu(null)}
          fontScalingItemClicked={(fontScaling: number) => {
            setFontScaling(fontScaling);
            gaEvent("Font Scaled from Font-Scaling Menu", "none", "none", 0);
            // setAnchorFontScalingMenu(null);
          }}
        />
      </div>
    </MuiThemeProvider>
  );

  function handleTextBooksChange(textBooks: string[]) {
    timeStamp = performance.now();
    setIsProgressBarVisible(true);
    setTextBooks(textBooks);
    isForceGetChapter = true;
    const chapterVerseLocation = new ChapterVerseLocation(chapter.chapterNumber, verseLocationForText); // keep same location
    setChapterVerseLocation(chapterVerseLocation);
  }

  function gotoLocation(gotoLocation: ChapterVerseLocation) {
    setIsAudioPlaying2(false);
    setIsSearchDialogOpen(false);
    setRecitationPointer(0);
    if (chapter.chapterNumber === gotoLocation.chapterNumber) {
      setVerseLocationForText(gotoLocation.verseLocationForText);
    } else {
      timeStamp = performance.now();
      setIsProgressBarVisible(true);
      setChapterVerseLocation(gotoLocation);
    }
  }

  function handleNewChapter(newChNum: number) {
    setRecitationPointer(0);
    setAnchorChapterMenu(false);
    setIsAudioPlaying2(false);
    const verseLocationForText = new VerseLocationForText(
      newChNum === 1 || newChNum === 9 ? 1 : 0,
      true,
      false,
      ++forceNewVerseNumberForText
    );
    if (newChNum === chapter.chapterNumber) {
      setVerseLocationForText(verseLocationForText);
    } else {
      timeStamp = performance.now();
      setIsProgressBarVisible(true);
      const chapterVerseLocation = new ChapterVerseLocation(newChNum, verseLocationForText);
      setChapterVerseLocation(chapterVerseLocation);
    }
  }

  function handleVerseRepeatInput(verseRepeat: number) {
    setVerseRepeat(verseRepeat);
    setVerseRepeatCounter(verseRepeat);
    setRecitationPointer(0);
  }

  function handlePlayPause() {
    if (!isAudioPlaying) {
      // We are really checking if audio is playing - because the button toggles the state
      const verseLocation = new VerseLocationForText(verseLocationForText.verseNumber, true, true, ++forceNewVerseNumberForText);
      setVerseLocationForText(verseLocation);
      gaEvent("Play/Pause Clicked", "none", "none", 0);
    }
    setIsAudioPlaying(!isAudioPlaying);
    setIsAudioPlaying2(true);
  }

  function handleNextButtonClicked() {
    handleNewChapter(chapter.chapterNumber < 114 ? chapter.chapterNumber + 1 : 1);
    gaEvent("Next Button Clicked", "none", "none", 0);
  }

  function handlePrevButtonClicked() {
    handleNewChapter(chapter.chapterNumber > 1 ? chapter.chapterNumber - 1 : 114);
    gaEvent("Prev Button Clicked", "none", "none", 0);
  }

  function getNextChapter() {
    handleNewChapter(chapter.chapterNumber < 114 ? chapter.chapterNumber + 1 : 1);
  }

  function handleVerseClicked(vNumber: number) {
    //setRecitationPointer(0);
    //setVerseRepeatCounter(verseRepeat);
    //setIsAudioPlaying2(false);
    if (vNumber === verseLocationForText.verseNumber) {
      //setVerseLocationForText(new VerseLocationForText(vNumber, false, false, ++forceNewVerseNumberForText));
    } else {
      setVerseLocationForText(new VerseLocationForText(vNumber, false, false, 0));
    }
    gaEvent("Verse Clicked", "none", "none", 0);
  }

  function handleVersesDialogItemClick(index: number) {
    setRecitationPointer(0);
    setIsAudioPlaying2(false);
    setIsVerseDialogOpen(false);
    if (index === verseLocationForText.verseNumber) {
      setVerseLocationForText(new VerseLocationForText(index, true, true, ++forceNewVerseNumberForText));
    } else {
      setVerseLocationForText(new VerseLocationForText(index, true, true, 0));
    }
    gaEvent("Verses Dialog, Verse Clicked", "none", "none", 0);
  }

  function handleAudioEnded() {
    setRecitationPointer(0);
    const localCounter = verseRepeatCounter - 1;
    if (localCounter <= 0) {
      setVerseRepeatCounter(verseRepeat);
      if (verseNumberForAudio < chapter.verseNumbers[chapter.verses[0].length - 1]) {
        setVerseLocationForText(new VerseLocationForText(verseNumberForAudio + 1, isAutoScroll, true, 0));
        setIsAudioPlaying2(false);
      } else if (isAutoNextChapter === true) {
        getNextChapter();
      } else if (isAutoNextChapter === !true) {
        setIsAudioPlaying2(false);
      }
    } else {
      setVerseRepeatCounter(localCounter);
      setIsAudioPlaying2(false);
      setIsAudioPlaying2(true);
    }
    gaEvent("Recitations", "none", recitations.join(", "), 0);
    gaEvent("Verse repeat", "none", verseRepeat.toString(), 0);
    gaEvent("Is auto scroll", "none", isAutoScroll.toString(), 0);
    gaEvent("Is auto next chapter", "none", isAutoNextChapter.toString(), 0);
  }

  function handleChapterRenderingDone() {
    const seconds = 0.25 * Math.round((performance.now() - timeStamp) / 250);
    gaEvent("Time to Load & Render Text", "none", `${seconds} sec.`, 0);

    setRecitationPointer(0);
    setIsProgressBarVisible(false);
    setVerseLocationForText(chapterVerseLocation.verseLocationForText);
    const newUrl = computeUrlPath(
      verseLocationForText.verseNumber,
      chapter.chapterNumber,
      defaultTextBooks,
      textBooks,
      defaultRecitations,
      recitations
    );
    // below, tried to make browser back/forward buttons works, but things did not work
    /*
    if (chapter.chapterNumber == 1 && getChapterNumberFromUrl(window.location.hash) == -1) {
      window.history.replaceState(null, null, newUrl);
    } else if (chapter.chapterNumber != getChapterNumberFromUrl(window.location.hash)) {
      window.history.pushState(null, null, newUrl);
    } else {
      window.history.replaceState(null, null, newUrl);
    } */
    window.history.replaceState(null, null, newUrl);

    gaEvent("Quran font", "none", quranFont, 0);
    gaEvent("Font scaling", "none", fontScaling.toString(), 0);
    gaEvent("Is parallel text", "none", isParallelText.toString(), 0);
    gaEvent("Is dark theme", "none", isDarkTheme.toString(), 0);
  }

  function handleVerseScrollingDone(verseNumber: number) {
    setTimeout(() => {
      setIsAudioPlaying2(true); // needed because setIsAudioPlaying2 can change very fast if there is no actual scrolling
    }, 100);
    setVerseNumberForAudio(verseNumber);
    window.history.pushState(
      null,
      null,
      computeUrlPath(verseLocationForText.verseNumber, chapter.chapterNumber, defaultTextBooks, textBooks, defaultRecitations, recitations)
    );
  }

  function handleNewChapterLoaded(newChapter: Chapter) {
    isForceGetChapter = false;
    setRecitationPointer(0);
    setChapter(newChapter);
    setVerseLocationForText(chapterVerseLocation.verseLocationForText);
  }

  function handleBookmarkClicked() {
    setAnchorPagesMenu(null);
    const verseLocation = new VerseLocationForText(verseNumberForAudio, true, true, 0);
    bookmark = new ChapterVerseLocation(chapter.chapterNumber, verseLocation);
    localStorage.set<ChapterVerseLocation>("BOOKMARK", bookmark);
    setSnackbarMessage("Verse " + chapter.chapterNumber + ":" + verseLocationForText.verseNumber + " bookmarked");
    setIsSnackbarOpen(true);
    gaEvent("Verse Bookmarked", "none", "none", 0);
  }

  function handleJuzMenuCLicked() {
    setAnchorPagesMenu(null);
    setAnchorJuzMenu(anchorPagesMenu);
  }

  function handleGotoBookmarkClicked() {
    setAnchorPagesMenu(null);
    if (bookmark != null) {
      gotoLocation(bookmark);
      gaEvent("Go To Bookmark", "none", "none", 0);
    }
  }

  function gotoNewUrl(url: string) {
    setAnchorPagesMenu(null);
    window.location.href = url;
  }

  function handleCloseSnackbar() {
    setIsSnackbarOpen(false);
  }

  function handleSearchTextBooksChange(textBooks: string[]) {
    setSearchTextBooks(textBooks);
  }

  function handleRecitationsChange(recitations: string[]) {
    setRecitations(recitations);
    setRecitationPointer(recitations.length - 1);
    window.history.replaceState(
      null,
      null,
      computeUrlPath(verseNumberForAudio, chapter.chapterNumber, defaultTextBooks, textBooks, defaultRecitations, recitations)
    );
  }

  function handleGotoJuz(juzIndex: number) {
    setAnchorJuzMenu(null);
    if (JUZTABLE[juzIndex][0] !== chapter.chapterNumber) {
      setIsProgressBarVisible(true);
    }
    const gotoLocationLocal = new ChapterVerseLocation(
      JUZTABLE[juzIndex][0],
      new VerseLocationForText(JUZTABLE[juzIndex][1], true, true, 0)
    );
    gotoLocation(gotoLocationLocal);
    gaEvent("Go to Juz Clicked", "none", "none", 0);
  }

  function handleShareClicked() {
    setIsShare(true);
    setIsShareVersesDialogOpen(true);
  }

  function handleCopyClicked() {
    setIsShare(false);
    setIsShareVersesDialogOpen(true);
  }

  function handleShareVersesDialog(message: string) {
    setIsShareVersesDialogOpen(false);
    if (message) {
      setSnackbarMessage(message);
      setIsSnackbarOpen(true);
    }
  }

  function saveNote(note: Note) {
    db.collection("notes").doc(note.documentId).update({
      text: note.text,
      modified: firebase.firestore.FieldValue.serverTimestamp()
    });
  }


  function addNoteHandler(verseNumber: number) {
    setAnchorNotesMenu(null);
    const user = firebaseApp.auth().currentUser;

    db.collection("notes").add({
      verseNumber: verseNumber,
      chapterNumber: chapter.chapterNumber,
      userId: user.uid,
      userName: user.displayName,
      userEmail: user.email,
      text: "",
      created: firebase.firestore.FieldValue.serverTimestamp(),
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      status: "",
      statusForAdmin: "",
      isPrivate: true
    });

    gaEvent("Notes", "none", "Note Added", 0);
  }

  function deleteNoteHandler(verseNumber: number) {
    const docId = notes.find((note: Note) => note.verseNumber === verseNumber).documentId;
    db.collection("notes").doc(docId).delete();
    setAnchorNotesMenu(null);
    gaEvent("Notes", "none", "Note Deleted", 0);
  }
}

App.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object
};

export default App;
