import { Recitation } from "../models/Recitation";

export const RECITATIONS: Recitation[] = [
  {
    name: "Abbad",
    code: "abbad-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/abbad-64/",
    scaling: 0.6,
    language: "Arabic"
  },
  {
    name: "Abdul Bassit (Mujawwad)",
    code: "abdulbassit-mujawwad-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/abdulbassit-mujawwad-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Abdul Bassit (Murattal)",
    code: "abdulbassit-murattal-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/abdulbassit-murattal-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Ad-Dussary",
    code: "addussary-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/addussary-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Al-Afasy",
    code: "alafasy-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/alafasy-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Al-Ajamy",
    code: "alajamy-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/alajamy-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Al-Banna",
    code: "albanna-32",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/albanna-32/",
    scaling: 0.8,
    language: "Arabic"
  },
  {
    name: "Al-Ghamdi",
    code: "alghamdi-40",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/alghamdi-40/",
    scaling: 0.85,
    language: "Arabic"
  },
  {
    name: "Al-Hudhaify",
    code: "alhudhaify-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/alhudhaify-64/",
    scaling: 0.85,
    language: "Arabic"
  },
  {
    name: "Al-Hussary",
    code: "alhussary-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/alhussary-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Alili",
    code: "alili-128",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/alili-128/",
    scaling: 0.5,
    language: "Arabic"
  },
  {
    name: "Almuaikali",
    code: "almuaikali-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/almuaikali-64/",
    scaling: 0.7,
    language: "Arabic"
  },
  {
    name: "Ar-Rifai",
    code: "arrifai-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/arrifai-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Ash-Shateri",
    code: "ashsheteri-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/ashsheteri-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Ash-Shuraym",
    code: "ashshuraym-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/ashshuraym-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "As-Sudais",
    code: "assudais-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/assudais-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Ayyoub",
    code: "ayyoub-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/ayyoub-64/",
    scaling: 0.7,
    language: "Arabic"
  },
  {
    name: "Basfar",
    code: "basfar-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/basfar-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Jaber",
    code: "jaber-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/jaber-64/",
    scaling: 0.7,
    language: "Arabic"
  },
  {
    name: "Minshawy",
    code: "minshawy-64",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/minshawy-64/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Salamah",
    code: "salamah-128",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/salamah-128/",
    scaling: 1.0,
    language: "Arabic"
  },
  {
    name: "Tunaiji",
    code: "tunaiji-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/tunaiji-64/",
    scaling: 0.9,
    language: "Arabic"
  },
  {
    name: "Community - God",
    code: "iv2-pol-bri-g",
    seperator: "-",
    bismiSingle: true,
    baseUrl: "https://audio.helloquran.com/iv2-pol-bri-g/",
    scaling: 1.0,
    language: "English"
  },
  {
    name: "Community - Allah",
    code: "iv2-pol-bri-a",
    seperator: "-",
    bismiSingle: true,
    baseUrl: "https://audio.helloquran.com/iv2-pol-bri-a/",
    scaling: 1.0,
    language: "English"
  },
  {
    name: "Community - Female - God",
    code: "iv2-pol-amy-g",
    seperator: "-",
    bismiSingle: true,
    baseUrl: "https://audio.helloquran.com/iv2-pol-amy-g/",
    scaling: 1.0,
    language: "English"
  },
  {
    name: "Community - Female - Allah",
    code: "iv2-pol-amy-a",
    seperator: "-",
    bismiSingle: true,
    baseUrl: "https://audio.helloquran.com/iv2-pol-amy-a/",
    scaling: 1.0,
    language: "English"
  },
  {
    name: "ClearQuran - God",
    code: "itani-64", // used old code to keep it compatible with previous edition of website
    seperator: "-",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/itani-48-b/",
    scaling: 1.0,
    language: "English"
  },
  {
    name: "ClearQuran - Allah",
    code: "itania-64", // used old code to keep it compatible with previous edition of website
    seperator: "-",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/itania-48-b/",
    scaling: 1.0,
    language: "English"
  },
  {
    name: "Sahih International",
    code: "sahihinternational-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/sahihinternational-64/",
    scaling: 1.0,
    language: "English"
  },
  {
    name: "Farhat Hashmi - Word by Word",
    code: "hashmi-32",
    seperator: "",
    bismiSingle: false,
    baseUrl: "https://media.quranco.com/audio/urdu-farhat-hasmi-word-by-word-32/",
    scaling: 1.0,
    language: "Urdu"
  },
  {
    name: "Shamshad Ali Khan",
    code: "ali-khan-46",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/urdu-shamshad-ali-khan-46/",
    scaling: 1.0,
    language: "Urdu"
  },
  {
    name: "Makarem",
    code: "makarem-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/makarem-64/",
    scaling: 1.0,
    language: "Persian"
  },
  {
    name: "Fooladvand ",
    code: "fooladvand-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/fooladvand-64/",
    scaling: 1.0,
    language: "Persian"
  },
  {
    name: "Besim Korkut",
    code: "korkut-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/bosnian-besim-korkut-64/",
    scaling: 1.0,
    language: "Bosnian"
  },
  {
    name: "Musayev",
    code: "balayev-64",
    seperator: "",
    bismiSingle: true,
    baseUrl: "https://media.quranco.com/audio/azerbaijani-balayev-64/",
    scaling: 1.0,
    language: "Azerbaijani"
  }
];
