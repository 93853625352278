import ReactGA from "react-ga4";

export function gaEvent(category: string, action: string, label: string, value: number): void {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value
  });
}
