import React, {ReactElement} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(() => ({
  progressBar: {
    zIndex: 2000
  },
  progressBarHidden: {
    visibility: "hidden"
  }
}));

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#FFCCFF",
    height: 6,
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%"
  },
  barColorPrimary: {
    backgroundColor: "#C30035",
    height: 6,
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%"
  }
})(LinearProgress);

interface ProgressBarProps {
  isVisible: boolean;
}

function ProgressBar(props: ProgressBarProps): ReactElement{
  const classes = useStyles(props);

  return (
    <div>
      <ColorLinearProgress className={props.isVisible === true ? classes.progressBar : classes.progressBarHidden} />
    </div>
  );
}

export default ProgressBar;
